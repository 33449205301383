import cookieconsent from 'cookieconsent';



let rtn = {};




rtn.init = () => {

    window.addEventListener("load", function() {
        window.cookieconsent.initialise({
            "palette": {
                "popup": {
                    "background": "#490718"
                },
                "button": {
                    "background": "#eead43",
                    "color": "#000"
                }
            },
            "theme": "edgeless",
            "position": "bottom-right",
            "content": {
                "message": "We use cookies, only to track visits to this website, we store no personal details.",
                "dismiss": "Ok",
                "href": "/privacy-policy"
            }
        })
    });

}


module.exports = rtn;
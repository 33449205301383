
import $ from 'jquery';


let rtn = {};

//activate elements on scroll position
    rtn.activateOnScrollY = function() {

    	const container = $('.container'),
    		  animatableElementCollection = $('.animate'),
    		  windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

        let topY = 0,
            ticking = false,
            windowHeightBuffer = Math.round(windowHeight / 3);
        
        const doSomething = function(scroll_pos) {

                //update the classname for the container
                if (scroll_pos > windowHeightBuffer) {
                    document.body.classList.add('in-page');
                } else if (scroll_pos < windowHeightBuffer) {
                    document.body.classList.remove('in-page');
                }

                //animate each animate element if in view                
                $.each(animatableElementCollection, function(i, el) {
                    var top = el.getBoundingClientRect().top;
                    if (scroll_pos > top || top < windowHeight) {
                        el.classList.add('active');
                    }
                })
            };

        window.addEventListener('scroll', function(e) {
            topY = window.scrollY;
            if (!ticking) {
                window.requestAnimationFrame(function() {
                    doSomething(topY);
                    ticking = false;
                });
                ticking = true;
            }
        });


        ///get current position on load
        doSomething(window.scrollY);

    }


   module.exports = rtn;

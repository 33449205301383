import forEach from 'lodash/forEach';
import delay from 'lodash/delay';

let rtn = {};


const desktop = (nav) => {

    const items = nav.querySelectorAll('ul > li'),
        showChildren = (e) => {

            const item = e.target.parentNode,
                children = item.querySelector('ul.children');

            if (children) {
                item.classList.add('active');
            }

        },
        hideChildren = (e) => {

            const item = e.target,
                children = item.querySelector('ul.children'),
                hide = () => {
                    if (children && !children.querySelector(':hover')) {
                        item.classList.remove('active');
                    }
                }

            delay(hide, 500);
        };

    forEach(items, (item) => {
        item.addEventListener('mouseover', showChildren, false);
        item.addEventListener('mouseleave', hideChildren, false);
    })

}



rtn.init = () => {



    const desktopNav = document.querySelector('.nav.desktop');
    if (desktopNav && window.getComputedStyle(desktopNav).getPropertyValue('display') !== 'none') {
        desktop(desktopNav);
    }

}


module.exports = rtn;
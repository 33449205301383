

import owlCarousel from 'owl.carousel';
import magnificPopup from 'magnific-popup';

let rtn = {};

rtn.headlineGallery = () => {


    const headlineGallery = $('.headline.gallery .owl-carousel');

    if (headlineGallery.length > 0) {

        headlineGallery.owlCarousel({
            items: 1,
            loop: true,
            center: true,
            mouseDrag: false,
            touchDrag: false,
            pullDrag: false,
            freeDrag: false,
            autoWidth: false,
            dots: false,
            autoplay: true,
            autoplayTimeout: 10000,
            autoplaySpeed: 2000,
            animateOut: 'fadeOut',
            animateIn: 'fadeIn'
        });

    }
    
}


rtn.speakingQuoteGallery = () => {

    const speakingQuoteGallery = $('body#speaking .quote.gallery .owl-carousel');

    if (speakingQuoteGallery.length > 0) {
        speakingQuoteGallery.owlCarousel({
            items: 1,
            loop: true,
            center: false,
            mouseDrag: false,
            touchDrag: false,
            pullDrag: false,
            freeDrag: false,
            autoWidth: false,
            dots: false,
            autoplay: true,
            autoplayTimeout: 8000,
            autoplaySpeed: 2000,
            animateOut: 'fadeOut',
            animateIn: 'fadeIn'
        });

    }
}



rtn.showreelsPhotosGallery = () => {

    const showreelsPhotosGallery = $('#media-gallery .grid-item');

    if (showreelsPhotosGallery.length > 0) {
        ///showreel and photos page lightbox
        showreelsPhotosGallery.magnificPopup({
            type: 'image',
            mainClass: 'mfp-fade',
            removalDelay: 300,
            gallery: {
                enabled: true
            }
        });

    }

}




module.exports = rtn;



//import aos from 'aos';
import $ from 'jquery';
import gallery from './service/gallery';
import masonry from './service/masonry';
import scroll from './service/scroll';
import nav from './service/nav';
import cookieNotice from './service/cookie-notice';



$(function() {



//activate the page after a short delay, and then implement the scroll functions
    setTimeout(function() {
        document.body.classList.add('active');
        scroll.activateOnScrollY();
        gallery.headlineGallery();
		    gallery.speakingQuoteGallery();
		    gallery.showreelsPhotosGallery();
        cookieNotice.init();
        nav.init();
    }, 200);


    setTimeout(function() {
        document.body.classList.add('ready');
        masonry.masonryOutline();
    }, 5000);

});

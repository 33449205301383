
import masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';


let rtn = {};

	

rtn.masonryOutline = function() {

	const masonryGrid = document.querySelector('.masonry');

	if (masonryGrid) {


        const mas = new masonry(masonryGrid, {
            itemSelector: '.grid-item',
            columnWidth: '.grid-sizer',
            percentPosition: true,
        });

        const imgLoad = imagesLoaded(masonryGrid);

        imgLoad.on('progress', function(i, el) {
            mas.layout();

            let
                count = (el.progressedCount - 1),
                timeOut = (100 * count);

                if (el.img) {
                setInterval(function() {
                    el.img.classList.add('active');
                }, timeOut);

            }
        
        })

    

    }

    };


module.exports = rtn;